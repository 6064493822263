.App {
  text-align: center;
}

#appContainer {
  /* max-width: 1280px; */
}

#hamburger {
  /* background-color: green; */
  height: 100px;
}

#header {
  /* background-color: red; */
}

#contentBody {
  /* background-color: purple; */
  /* height: 1000vh; */
  font-size: 1.05em;
}

#right-gutter {
  /* background-color: aqua; */
}

#footer {
  /* background-color: bisque; */
  height: 100px;
}
