#navGroup {
  border: none; 
}

#navbar {
  /* background-color: cyan; */
  background-color: white;
  padding-top: 20vh;
  align-items: flex-start;
  z-index:10;
  margin-left: 0vh;
}

.navItem {
  border: none;
  color: #393B3F;
}

.list-group-item.active {
  background-color: #EBEDEF;
  /* in prep for lines */
  /* background-color: white; */
  border-color: #EBEDEF;
  color: #393B3F;
}

.list-group-item:hover {
  /* in prep for lines */
  /* background-color: white; */
  color: #393B3F;
}