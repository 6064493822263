@import "~react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important;
}

.customDatePickerGroup .react-datepicker-wrapper .react-datepicker__input-container input { 
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999;
}

.datePickerLabel {
  font-size: .90em;
}

#publishDateContainer {
  padding-top: 1vh;
}